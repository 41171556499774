















































import {Vue } from 'vue-property-decorator';
import GroupListModalTemplate from '@/components/templates/GroupListModalTemplate/GroupListModalTemplate.vue';
import * as Config from '@/config';
import store from '@/store';
export interface DataType {
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  dataGroupsModal: any;
  rowinErrDialog: number;
  successItemProcess: number;
  errorMessageProcess: any;
  progress: number;
  isFinishProcess: boolean;
  totalDataRowProcess: number;
  isGroupEditModalShow: boolean;
  authority: any;
  isEdit: any;
  groupExpanedGate: Map<string, GroupGate>; // data Gate of Group
  isReRenderGate: number;
  isRemoveGateSelected: number;

  groupExpanedUser: Map<string, GroupUser>; // data User of Group
  isReRenderUser: number;
  isRemoveUserSelected: number;
  isRemoveGroupSelected: number;
  groupIdAction: string;
  dataAction: any;
}
export interface GroupGate {
  currPageGate?: number;
  totalGateCount?: number;
  isGetDataGate?: boolean;
  data?: any;
}

export interface GroupUser {
  currPageUser?: number;
  totalUserCount?: number;
  isGetDataUser?: boolean;
  data?: any;
}
export default Vue.extend({
  name: 'GateListModalPage',
  components: {
    GroupListModalTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    dataGroupsModal: {
      dataOri: [],
      data: [],
      total: 0,
      loading: false,
    },
    rowinErrDialog: 0,
    successItemProcess: 0,
    errorMessageProcess: [],
    progress: 0,
    isFinishProcess: false,
    totalDataRowProcess: 0,
    isGroupEditModalShow: false,
    authority: store.state.auth,
    isEdit: false,
    groupExpanedGate: new Map(),
    isReRenderGate: 0,
    isRemoveGateSelected: 0,
    groupExpanedUser: new Map(),
    isReRenderUser: 0,
    isRemoveUserSelected : 0,
    isRemoveGroupSelected: 0,
    groupIdAction: '',
    dataAction: [],
  }),
  created() {
    //
    localStorage.setItem('isVaildDataGroupModal', 'true');
    if (typeof localStorage.groupModalSortKey === 'undefined') {
      localStorage.setItem('groupModalSortKey', 'registered');
    }
    if (typeof localStorage.groupModalSortName === 'undefined') {
      localStorage.setItem('groupModalSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroupModal === 'undefined') {
      localStorage.setItem('pageSizeGroupModal', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroupModal);
    }
    this.getListGroupsModal();
    localStorage.removeItem('groupInfo');
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    //
  },
  methods: {
    //
    changePerPage(value: any): any {
      this.perPage = value;
      this.currPage = 1;
      localStorage.setItem('pageSizeGroupModal', value);
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    changePage(value: any): any {
      this.currPage = value;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.currPage = 1;
      this.scorp = value.targets;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    getListGroupsModal(): any {
      this.resetData();
      if (this.authority.isAdmin) {
        this.getListGroups();
      } else {
        this.dataGroupsModal.loading = true;
        this.getListGroupUserLogin(1);
      }
    },
    getListGroupUserLoginChange() {
        this.dataGroupsModal.loading = true;
        let dataOri = this.dataGroupsModal.dataOri;
        if (this.keyword !== '') {
          dataOri = this.dataGroupsModal.dataOri.filter((element: any) =>
          element.name.toLowerCase().includes(this.keyword.toLowerCase()) === true);
        }
        const data  = Array.of(...dataOri);
        this.dataGroupsModal.data =
        data.slice((this.currPage - 1) * this.perPage, this.currPage * this.perPage);
        setTimeout(() => {
          this.dataGroupsModal.total = dataOri.length;
          this.dataGroupsModal.loading = false;
        }, 500);
      },
    getListGroupUserLogin(page: any) {
      const apiLink = Config.GROUP_ADMINISTRATOR_API +
        '?userId=' + encodeURIComponent(localStorage.userProfileId ) + '&limit=1000' + '&page=' + page;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            const data = response.body.groupAdministrators;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                element.isData = false;
                this.dataGroupsModal.dataOri.push(element);
                const result: any = this.getGroupDetail(element);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            const dataResult = this.dataGroupsModal.dataOri.filter((element: any) =>  element.isData === true);
            this.dataGroupsModal.data  = dataResult;
            this.dataGroupsModal.total = dataResult.length;
            this.currPage = 1;
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getListGroupUserLogin(page);
            } else {
              //
              this.dataGroupsModal.loading = false;
            }
          } else {
            this.dataGroupsModal.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataGroupsModal.loading = false;
        });
    },
    getGroupDetail(item: any) {
        return new Promise((resolve, reject) => {
          let apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(item.groupId) + '&isExceptOptionalGate=true';
          if (localStorage.isVaildDataGroupModal === 'true') {
            apiLink += '&isValid=true';
          }
          this.$http.get(apiLink)
            .then((response: any) => {
              const index = this.dataGroupsModal.dataOri.findIndex((x: any) =>
              x.groupId === item.groupId);
              if (index >= 0) {
                this.dataGroupsModal.dataOri[index] = response.body.groups[0];
                this.dataGroupsModal.dataOri[index].isData = true;
              }
              resolve(true);
            })
            .then( null, (err: any) => {
              resolve(false);
            }); // catch
        });
    },
    getListGroups() {
      this.dataGroupsModal.loading = true;
      let apiLink = Config.GROUP_LIST_API + '?limit=' + localStorage.pageSizeGroupModal +
      '&page=' + this.currPage + '&sortKey=' +
      localStorage.groupModalSortName + ':' + localStorage.groupModalSortKey;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroupModal === 'true') {
        apiLink += '&isValid=true';
      }
      apiLink += '&embed=passableTimeSetting&isExceptOptionalGate=true';
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.groups)) {
            this.dataGroupsModal.data = response.body.groups;
            this.dataGroupsModal.total = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
            this.dataGroupsModal.loading = false;
          } else {
            this.dataGroupsModal.total = 0;
            this.currPage = 1;
            this.dataGroupsModal.loading = false;
          }
          this.dataGroupsModal.loading = false;
        })
        .then( null, (err: any) => {
          this.dataGroupsModal.loading = false;
          this.dataGroupsModal.data = [];
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    async clickGroupsAction(eventArgs: any) {
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;
      const data = eventArgs.typeAction > 2 ? this.dataAction : eventArgs.data;
      this.totalDataRowProcess = data.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of data) {
        let result;
        if (eventArgs.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (eventArgs.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        } else if (eventArgs.typeAction === 2) {
          //  delete
          let resultDel;
          let resultdeleteRe;
          resultDel = await this.deleteGroup(item);
          if (resultDel) {
            resultdeleteRe = await this.deleteRelationGateGroup(item);
            if (resultdeleteRe) {
              result = await this.putGroup(item);
            }
          }
        } else if (eventArgs.typeAction === 3) {
          // remove from to group
          result = await this.removeGateFromGroup(item.relationId, item.gate.gateId);
        } else if (eventArgs.typeAction === 4) {
          // remove user from group
          result = await this.removeUserFromGroup(item.groupPassageId, item.user.userId);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.dataGroupsModal = {
        dataOri: [],
        data: [],
        total: 0,
        loading: false,
      };
    },
    clickAddGroup(eventArgs: any) {
      this.isGroupEditModalShow = true;
      localStorage.setItem('isEdit', 'false');
      this.isEdit = false;
    },
    ChangeProperty(eventArgs: any) {
      this.isGroupEditModalShow = false;
      if (eventArgs) {
        this.getListGroupsModal();
      }
    },
    clickEditGroup(eventArgs: any) {
      this.isGroupEditModalShow = true;
      localStorage.setItem('isEdit', 'true');
      this.isEdit = true;
    },
    getGroupIsValid(val: any) {
      localStorage.setItem('isVaildDataGroupModal', val.toString());
      this.getListGroupsModal();
    },
    // changeExpandedGroup
    changeExpandedGroup(event: any) {
      if  (event.value) {
        const data = event.item;

        this.groupExpanedGate = new Map();
        const gate: GroupGate = {
          isGetDataGate: false,
          currPageGate: 1,
          totalGateCount: 0,
          data: [],
        };
        this.groupExpanedGate.set(data.groupId, gate);
        this.getGateOfGroup(data.groupId, 1);

        this.groupExpanedUser = new Map();
        const user: GroupUser = {
          isGetDataUser: false,
          currPageUser: 1,
          totalUserCount: 0,
          data: [],
        };
        this.groupExpanedUser.set(data.groupId, user);
        this.getUserOfGroup(data.groupId, 1);
      }
    },
    getGateOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedGate.get(groupId);
      if (data !== undefined) {
        data.isGetDataGate = true;
        data.currPageGate = page;
        this.reRenderGate();
        const apiLink = Config.RELATION_GATE_GROUP +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:gateId&embed=gate&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.relationsGateGroup)) {
              data.totalGateCount = response.body.maxRecords;
              data.currPageGate = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.relationsGateGroup;
            } else {
              data.totalGateCount = 0;
              data.currPageGate = 1;
            }
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataGate = false;
            this.reRenderGate();
            this.removegateSelected();
          }); // catch
      }
    },
    changePageGate(event: any) {
      this.getGateOfGroup(event.groupId, event.page);
    },
    getUserOfGroup(groupId: string, page: number) {
      const data = this.groupExpanedUser.get(groupId);
      if (data !== undefined) {
        data.isGetDataUser = true;
        data.currPageUser = page;
        this.reRenderUser();
        const apiLink = Config.GROUP_PASSAGE +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:userId&embed=user';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.groupPassages)) {
              data.totalUserCount = response.body.maxRecords;
              data.currPageUser = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.groupPassages;
            } else {
              data.totalUserCount = 0;
              data.currPageUser = 1;
            }
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          })
          .then( null, (err: any) => {
            data.isGetDataUser = false;
            this.reRenderUser();
            this.removeUserSelected();
          }); // catch
      }
    },
    changePageUser(event: any) {
      this.getUserOfGroup(event.groupId, event.page);
    },

    reRenderGate() {
      if (this.isReRenderGate < 100) {
          this.isReRenderGate++;
        } else {
          this.isReRenderGate = 0;
        }
    },

    reRenderUser() {
      if (this.isReRenderUser < 100) {
          this.isReRenderUser++;
        } else {
          this.isReRenderUser = 0;
        }
    },
    removegateSelected() {
      if (this.isRemoveGateSelected < 100) {
        this.isRemoveGateSelected++;
      } else {
        this.isRemoveGateSelected = 0;
      }
    },
    removeUserSelected() {
      if (this.isRemoveUserSelected < 100) {
        this.isRemoveUserSelected++;
      } else {
        this.isRemoveUserSelected = 0;
      }
    },
    removeGroupSelected() {
      if (this.isRemoveGroupSelected < 100) {
        this.isRemoveGroupSelected++;
      } else {
        this.isRemoveGroupSelected = 0;
      }
    },
    showDialogConfirm(value: any) {
      this.dataAction = [];
      this.dataAction = value.data;
      if (value.groupId !== undefined) {
        this.groupIdAction = value.groupId;
      }
    },
    removeGateFromGroup(relationId: string, gateId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP +
          '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = gateId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },

    removeUserFromGroup(groupPassageId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE +
          '/' + encodeURIComponent(groupPassageId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = '該当するグループがありません。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = userId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },
    closeModalProgress(typeAction: any) {
      if (this.successItemProcess > 0) {
        if (typeAction === 0 || typeAction === 1 || typeAction === 2) {
          this.getListGroups();
        } else if (typeAction === 3) {
          // gate
          this.removegateSelected();
          const gate: GroupGate = {
            isGetDataGate: false,
            currPageGate: 1,
            totalGateCount: 0,
            data: [],
          };
          this.groupExpanedGate.set(this.groupIdAction, gate);
          this.getGateOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        } else if (typeAction === 4) {
          // user
          this.removeUserSelected();
          this.groupExpanedUser = new Map();
          const user: GroupUser = {
            isGetDataUser: false,
            currPageUser: 1,
            totalUserCount: 0,
            data: [],
          };
          this.groupExpanedUser.set(this.groupIdAction, user);
          this.getUserOfGroup(this.groupIdAction, 1);
          this.getListGroups();
        }
      }
    },
    clickGroupDetail(data: any) {
      console.log('data', data);
      localStorage.setItem('groupInfo', JSON.stringify(data));
      localStorage.setItem('isLoad', '1');
      this.$emit('click-group-detail', data.groupId);
    },
    deleteGroup(groupId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(groupId))
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            let result;
            for (const item of response.body.groupPassages) {
              result = await this.deleteGroupItem(item.groupPassageId);
              if (!result) {
                resolve(false);
              }
            }
            resolve(true);
          } else {
            const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          if (err.status === 404) {
            resolve(true);
          } else {
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }
          }); // catch
      });
    },
    deleteGroupItem(groupPassageId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE + '/' + encodeURIComponent(groupPassageId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = groupPassageId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupPassageId + '>><span>' + errorName + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteRelationGateGroup(groupId: any) {
      return new Promise((resolve) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?groupId=' + encodeURIComponent(groupId) + '&isExceptOptionalGate=true')
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
              // const relationId = response.body.relationsGateGroup[0].relationId;
              let result;
              for (const item of response.body.relationsGateGroup) {
                result = await this.deleteRelationGateGroupItem(item.relationId);
                if (!result) {
                  resolve(false);
                }
              }
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_404 + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          }); // catch
      });
    },
    deleteRelationGateGroupItem(relationId: any) {
      return new Promise((resolve) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(relationId))
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              const rowstatus = relationId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorMessageLogs = Config.ERROR_403;
                } else {
                  errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorMessageLogs + '</span>';
            if (err.status === 404) {
              resolve(true);
            } else {
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          });
      });
    },
    putGroup(groupId: any) {
      return new Promise((resolve) => {
        let data;
        data = {
          isValid: false,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              // this.successMessageLogs = Config.DELETE_API_SUCCESS;
              resolve(true);
            } else {
              const rowstatus = groupId + '>><span>' + Config.ERROR_DEFAULT + '</span>';
              this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorMessageLogs = '';
            switch (err.status) {
              case 400:
                errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                errorMessageLogs = '入力されたグループ名は既に使用されています。他のグループ名を入力してください。';
                break;
              case 500:
                errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                errorMessageLogs = Config.ERROR_503;
                break;
              default:
                errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = groupId + '>><span>' + errorMessageLogs + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          });
      });
    },
  },
});
