var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template common-class" },
    [
      _vm.isPage === "detail-user-group-gate" ||
      _vm.isPage === "add-user-group-gate" ||
      this.isPage === "edit-user-group-gate"
        ? _c(
            "v-row",
            {
              staticClass: "mt-2 mb-1 ml-5 mr-5",
              attrs: { justify: "space-between", id: "expanded-data" }
            },
            [
              _c("div", { staticClass: "allig-title mt-1" }),
              _vm._v(" "),
              _vm.authority.isAdmin === true
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                disabled:
                                  _vm.dataModal.gateOfGroup.loading === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showGroup()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n      通行権限グループ(ユーザー設定)を追加する\n      "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isPage === "add-user-group-gate"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: {
                                    disabled: _vm.selectedGroupIds.length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.isConfirm = true
                                      _vm.type = "del"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n      チェックしたユーザー設定を削除する\n      "
                                  )
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: {
                                    disabled: _vm.selectedGroupIds.length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.isConfirm = true
                                      _vm.type = "del"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n      チェックしたユーザー設定を削除する\n      "
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user mt-0 pt-0 mb-0 pb-0" },
          [
            _vm.isPage === "group"
              ? _c("group-list-modal-table", {
                  attrs: {
                    authority: _vm.authority,
                    dataModalGroup: _vm.dataModal.groupAll
                  },
                  on: {
                    "change-page": function($event) {
                      return _vm.$emit("change-page-group", $event)
                    },
                    "add-group": function($event) {
                      return _vm.addGroup($event)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPage === "add-user-group-gate"
              ? _c("group-gate-table", {
                  attrs: {
                    isPage: _vm.isPage,
                    userId: "",
                    ExpandedGroup:
                      _vm.dataModal.gateOfGroup.data.length > 0
                        ? _vm.dataModal.gateOfGroup.data
                        : [],
                    pageGroup:
                      _vm.dataModal.gateOfGroup.page !== undefined
                        ? _vm.dataModal.gateOfGroup.page
                        : 1,
                    groupTotalCount:
                      _vm.dataModal.gateOfGroup.total !== undefined &&
                      _vm.dataModal.gateOfGroup.total
                        ? _vm.dataModal.gateOfGroup.total
                        : 0,
                    loading:
                      _vm.dataModal.gateOfGroup.loading !== undefined
                        ? _vm.dataModal.gateOfGroup.loading
                        : false,
                    groupDetail: [],
                    groupId: "",
                    authority: _vm.authority,
                    isClear: _vm.isClear
                  },
                  on: {
                    "select-Ids": function($event) {
                      return _vm.selectIds($event)
                    },
                    "change-page-group": function($event) {
                      return _vm.$emit("change-page-group", $event)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPage === "detail-user-group-gate"
              ? _c("group-gate-table", {
                  attrs: {
                    loading:
                      _vm.dataModal.gateOfGroup.loading !== undefined
                        ? _vm.dataModal.gateOfGroup.loading
                        : false,
                    isPage: _vm.isPage,
                    authority: _vm.authority,
                    userId: _vm.userId,
                    isClear: _vm.isClear,
                    ExpandedGroup:
                      _vm.dataModal.gateOfGroup.data.length > 0
                        ? _vm.dataModal.gateOfGroup.data
                        : [],
                    pageGroup:
                      _vm.dataModal.gateOfGroup.page !== undefined
                        ? _vm.dataModal.gateOfGroup.page
                        : 1,
                    groupTotalCount:
                      _vm.dataModal.gateOfGroup.total !== undefined &&
                      _vm.dataModal.gateOfGroup.total
                        ? _vm.dataModal.gateOfGroup.total
                        : 0,
                    messageSetting: _vm.dataModal.gateOfGroup.messageSetting
                  },
                  on: {
                    "select-Ids": function($event) {
                      return _vm.selectIds($event)
                    },
                    "change-page-group": function($event) {
                      return _vm.$emit("change-page-group", $event)
                    },
                    "add-group-pass": function($event) {
                      return _vm.$emit("add-group-pass", $event)
                    },
                    "close-popup": function($event) {
                      return _vm.$emit("close-popup", $event)
                    }
                  }
                })
              : _vm.isPage === "edit-user-group-gate"
              ? _c("group-gate-table", {
                  attrs: {
                    loading:
                      _vm.dataModal.gateOfGroup.loading !== undefined
                        ? _vm.dataModal.gateOfGroup.loading
                        : false,
                    isPage: _vm.isPage,
                    authority: _vm.authority,
                    userId: _vm.userId,
                    isClear: _vm.isClear,
                    ExpandedGroup:
                      _vm.dataModal.gateOfGroup.data.length > 0
                        ? _vm.dataModal.gateOfGroup.data
                        : [],
                    pageGroup:
                      _vm.dataModal.gateOfGroup.page !== undefined
                        ? _vm.dataModal.gateOfGroup.page
                        : 1,
                    groupTotalCount:
                      _vm.dataModal.gateOfGroup.total !== undefined &&
                      _vm.dataModal.gateOfGroup.total
                        ? _vm.dataModal.gateOfGroup.total
                        : 0,
                    messageSetting: _vm.dataModal.gateOfGroup.messageSetting
                  },
                  on: {
                    "select-Ids": function($event) {
                      return _vm.selectIds($event)
                    },
                    "change-page-group": function($event) {
                      return _vm.$emit("change-page-group", $event)
                    },
                    "close-popup": function($event) {
                      return _vm.$emit("close-popup", $event)
                    }
                  }
                })
              : _vm.isPage === "group-load-all-user" ||
                _vm.isPage === "aff-load-all-user" ||
                _vm.isPage === "affiliation-add-new"
              ? _c("user-list-modal-table", {
                  attrs: {
                    isPage: _vm.isPage,
                    authority: _vm.authority,
                    dataModalUser: _vm.dataModal.userAll,
                    loading: _vm.dataModal.userAll.loading,
                    loadingParent: _vm.loadingParent,
                    departmentId: _vm.departmentId,
                    groupPassages: _vm.groupPassages,
                    dataAdded: _vm.dataAdded
                  },
                  on: {
                    "change-page": function($event) {
                      return _vm.$emit("change-page-user", $event)
                    },
                    "change-per-page": function($event) {
                      return _vm.$emit("change-per-page-user", $event)
                    },
                    "search-all-user": function($event) {
                      return _vm.$emit("search-all-user", $event)
                    },
                    "add-user": function($event) {
                      return _vm.addUser($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.isAction === "add-user-to-aff"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("他のユーザーを追加")]
                  )
                : _vm.isAction === "del" || _vm.type === "del"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("チェックしたユーザー設定を削除")]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("他のユーザーを追加")]
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.dataProgress.total >= 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          staticStyle: { "pointer-events": "none" },
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.dataProgress.progressLoad,
                            callback: function($$v) {
                              _vm.$set(_vm.dataProgress, "progressLoad", $$v)
                            },
                            expression: "dataProgress.progressLoad"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.dataProgress.progressLoad)
                              ) + "%"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.type === "add" && _vm.dataProgress.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataProgress.total) +
                                "件中" +
                                _vm._s(_vm.dataProgress.successItem) +
                                " 件を追加しました。"
                            )
                          ])
                        : _vm.type === "del" && _vm.dataProgress.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataProgress.total) +
                                "件中" +
                                _vm._s(_vm.dataProgress.successItem) +
                                " 件を削除しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataProgress.errorMessage.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.dataProgress.errorMessage, function(
                                  item
                                ) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataProgress.isFinish
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c("success-button", {
                            attrs: { text: "OK" },
                            on: {
                              click: function($event) {
                                return _vm.closeModalGroup()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.isShowGroup,
            callback: function($$v) {
              _vm.isShowGroup = $$v
            },
            expression: "isShowGroup"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("ModalLoading", {
                attrs: { loading: _vm.dataModal.groupAll.loading }
              }),
              _vm._v(" "),
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(
                  _vm._s(_vm.userName) +
                    "が新たに所属する通行権限を選択してください"
                )
              ]),
              _vm._v(" "),
              _vm.isPage === "add-user-group-gate"
                ? _c(
                    "div",
                    [
                      _vm.isShowGroup
                        ? _c("group-list-modal-table", {
                            attrs: {
                              isPage: "add",
                              isShowGroup: _vm.dataModal.groupAll.loading,
                              dataGroupAdd: _vm.dataGroupAdd,
                              dataModalGroup: _vm.dataModal.groupAll,
                              dataGroupPass: _vm.dataModal.groupPass.data
                            },
                            on: {
                              "change-page": function($event) {
                                return _vm.$emit("change-page-group", $event)
                              },
                              "select-Ids": function(Ids) {
                                _vm.selectedGroupAddIds = Ids
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm.isShowGroup
                        ? _c("group-list-modal-table", {
                            attrs: {
                              isPage: "edit-user-group-gate",
                              dataModalGroup: _vm.dataModal.groupAll,
                              dataGroupPass: _vm.dataModal.groupPass.data
                            },
                            on: {
                              "change-page": function($event) {
                                return _vm.$emit("change-page-group", $event)
                              },
                              "select-Ids": function(Ids) {
                                _vm.selectedGroupAddIds = Ids
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _vm.isPage === "add-user-group-gate"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled:
                                  _vm.selectedGroupAddIds.length === 0 ||
                                  _vm.dataModal.groupAll.loading === true
                              },
                              on: {
                                click: function($event) {
                                  _vm.addGroupDirect(_vm.selectedGroupAddIds)
                                  _vm.isShowGroup = false
                                }
                              }
                            },
                            [_vm._v("選択した通行権限グループに所属する")]
                          )
                        : _vm.isPage === "edit-user-group-gate"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled:
                                  _vm.selectedGroupAddIds.length === 0 ||
                                  _vm.dataModal.groupAll.loading === true
                              },
                              on: {
                                click: function($event) {
                                  _vm.addGroupInEdit(_vm.selectedGroupAddIds)
                                  _vm.isShowGroup = false
                                }
                              }
                            },
                            [_vm._v("選択した通行権限グループに所属する")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled: _vm.selectedGroupAddIds.length === 0
                              },
                              on: {
                                click: function($event) {
                                  _vm.addGroup(_vm.selectedGroupAddIds)
                                  _vm.isShowGroup = false
                                }
                              }
                            },
                            [_vm._v("選択した通行権限グループに所属する")]
                          ),
                      _vm._v(" "),
                      _c("cancel-button", {
                        attrs: { text: "キャンセル" },
                        on: {
                          click: function($event) {
                            _vm.isShowGroup = false
                            _vm.closeAdd()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isConfirm,
            callback: function($$v) {
              _vm.isConfirm = $$v
            },
            expression: "isConfirm"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "common-modal" },
            [
              _c("ModalTitle", {
                attrs: { title: "チェックしたユーザー設定を削除" },
                on: {
                  click: function($event) {
                    _vm.isConfirm = false
                  }
                }
              }),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-0 pb-5 text-progress" }, [
                _vm._v("チェックしたユーザー設定を削除しますか？")
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _vm.isPage === "add-user-group-gate"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeGroupDirect()
                                }
                              }
                            },
                            [_vm._v("\n          削除\n        ")]
                          )
                        : _vm.isPage === "edit-user-group-gate"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeGroupInEdit()
                                }
                              }
                            },
                            [_vm._v("\n          削除\n        ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeGroup()
                                }
                              }
                            },
                            [_vm._v("\n          削除\n        ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgressDirect,
            callback: function($$v) {
              _vm.showProgressDirect = $$v
            },
            expression: "showProgressDirect"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.isAction === "del" || _vm.type === "del"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("チェックしたユーザー設定を削除")]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("選択した通行権限グループに所属")]
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.dataProgressDirect.total >= 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          staticStyle: { "pointer-events": "none" },
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.dataProgressDirect.progressLoad,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dataProgressDirect,
                                "progressLoad",
                                $$v
                              )
                            },
                            expression: "dataProgressDirect.progressLoad"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.dataProgressDirect.progressLoad)
                              ) + "%"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.type === "add" &&
                      _vm.dataProgressDirect.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataProgressDirect.total) +
                                "件中" +
                                _vm._s(_vm.dataProgressDirect.successItem) +
                                " 件を追加しました。"
                            )
                          ])
                        : _vm.type === "del" &&
                          _vm.dataProgressDirect.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataProgressDirect.total) +
                                "件中" +
                                _vm._s(_vm.dataProgressDirect.successItem) +
                                " 件を削除しました。"
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c("success-button", {
                        attrs: { text: "OK" },
                        on: {
                          click: function($event) {
                            return _vm.closeProgressDirect()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }